import { Backdrop, Box } from '@mui/material'
import { ReactElement, useEffect, useState } from 'react'
import { commonConfig } from '../environment'
import Lottie, { Options } from 'react-lottie'
import { AnyObject } from '../utils/utils'

const path = commonConfig.app.theme === 'default' ? 'default' : 'whitelabel'

/**
 * This Component is for Loading indication.
 * @param {Object} this.props.
 */
const Loader = (): ReactElement => {
	const [lottieData, setLottieData] = useState<Options | null>(null)

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: lottieData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	}

	useEffect(() => {
		const loadLottieData = async () => {
			try {
				const module1 = await import(`../resources/${path}/images/lottie/loader.json`)
				setLottieData(module1.default)
			} catch (error) {
				// Fallback to default Lottie if the dynamic import fails
				const defaultModule = await import('../resources/default/images/lottie/loader.json')
				setLottieData(defaultModule.default as AnyObject)
			}
		}

		loadLottieData()
	}, [path])
	return (
		<Box>
			<Backdrop
				style={{ backgroundColor: 'rgba(0, 0, 0, 0.08)', zIndex: 99999 }}
				classes={{
					root: 'MuiBackdrop-root-loader',
				}}
				open={true}
			>
				{lottieData && <Lottie options={defaultOptions} height={200} width={200} />}
				{/* <CustomIcon name={iconKeys.hereSectionImg} style={{ height: '200px', width: '200px' }} /> */}
			</Backdrop>
		</Box>
	)
}

export default Loader
