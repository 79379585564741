// Constants for HTTP methods
export const apiMethods = {
	get: 'get',
	patch: 'patch',
	post: 'post',
	put: 'put',
	delete: 'delete',
}

export const baseEndPoints = {
	version: '/v1',
}
// Constants for common API headers
export const apiHeaders = {
	acceptType: 'application/json',
	preventDefaultLoader: 'prevent-default-loader',
	preventDefaultToastSuccess: 'prevent-default-toast-success',
	preventDefaultToastFailure: 'prevent-default-toast-failure',
	today: 'today',
	timezone: 'timezone',
	appVersion: 'version-no',
	authToken: 'authToken',
	language: 'language',
	deviceType: 'devicetype',
	accessToken: 'accessToken',
	refreshApi: 'refreshApi',
	contentType: 'Content-Type',
	uploadToS3: 'uploadToS3',
	accessType: 'accessType',
}
const initialEndPoints = {
	logout: `${baseEndPoints.version}/logout`,
	sendOtp: `${baseEndPoints.version}/send-otp`,
	verifyOtp: `${baseEndPoints.version}/verify-otp`,
	verifyEmailOtp: `/auth${baseEndPoints.version}/verifyEmailOtp`,
	verifyMobileOtp: `${baseEndPoints.version}/verify-phone-otp`,
	updateProfile: `${baseEndPoints.version}/update-profile`,
	fetchProfile: `${baseEndPoints.version}/fetch-profile`,
	fetchGames: `${baseEndPoints.version}/fetch-games`,
	startGameSession: `${baseEndPoints.version}/start-game-session`,
	endGameSession: `${baseEndPoints.version}/end-game-session`,
	fetchLeaderboard: `${baseEndPoints.version}/fetch-leaderboard`,
	refreshToken: `/auth${baseEndPoints.version}/refresh-token`,

	fetchGamesProgress: `${baseEndPoints.version}/fetch-leaderboard-all-games`,
	completeUserProfile: `${baseEndPoints.version}/complete-user-profile`,
	sendReauthentication: `${baseEndPoints.version}/send-reauthentication`,
	verifyReauthentication: `${baseEndPoints.version}/verify-reauthentication`,
	deleteUser: `${baseEndPoints.version}/delete-user`,

	listAllGames: `${baseEndPoints.version}/list-games-for-playlist`,
	fetchMiscellaneous: `${baseEndPoints.version}/get-miscellaneous`,
	getS3LinkForPlaylistImage: `/game-playlist${baseEndPoints.version}/file-upload`,
	createGamesPlaylist: `/game-playlist${baseEndPoints.version}/create-games-playlist`,

	fetchGameplaylists: `/game-playlist${baseEndPoints.version}/fetch-games-playlist`,
	listMembers: '/game-playlist/v1/list-members',
	unJoinMembers: '/game-playlist/v1/members-unjoined',
	updateGamesPlaylist: `/game-playlist${baseEndPoints.version}/update-games-playlist`,
	inviteAFriend: `/friends/${baseEndPoints.version}/invite-a-friend`,
	updateRemoveGamesPlaylist: `/game-playlist${baseEndPoints.version}/update-playlist-games`,

	deletePlaylist: `/game-playlist${baseEndPoints.version}/delete-playlist`,
	acceptRejectPlaylistInvite: `/game-playlist${baseEndPoints.version}/accept-reject-playlist-invitation`,
	unJoinPlaylist: `/game-playlist${baseEndPoints.version}/members-unjoined`,

	listMyFriends: `/friends${baseEndPoints.version}/list-friends`,
	addFriendsToPlayList: `/game-playlist${baseEndPoints.version}/invite-members-to-playlist`,

	listMyPlaylists: `/game-playlist${baseEndPoints.version}/list-playlist-to-add-games`,
	addGameToPlaylists: `/game-playlist${baseEndPoints.version}/add-games-to-multiple-playlist`,

	listFriendsSentReceived: `/friends${baseEndPoints.version}/list-sent-received-friends`,
	ignoreAcceptFriend: `/friends${baseEndPoints.version}/accept-reject-invitation`,
	unFriend: `/friends${baseEndPoints.version}/unfriend`,

	ignoreAcceptFriendFromEmail: `friends${baseEndPoints.version}/accept-friend-invite`,
	fetchinvitationDetails: `${baseEndPoints.version}/fetch-invitation-details`,

	fetchGamePreference: `${baseEndPoints.version}/fetch-game-preference`,
	updateGamePreference: `${baseEndPoints.version}/update-game-preference`,
	profilePicUpdate: `${baseEndPoints.version}/update-profile-picture?save=`,
	googleCallback: `/auth${baseEndPoints.version}/google/callback`,

	viewDashboard: `/dashboard/${baseEndPoints.version}/dashboard`,
	allGamesYearlyData: `${baseEndPoints.version}/fetch-leaderboard-all-games-yearly-data`,
}

export const getEndPoints = () => {
	return initialEndPoints
}

// Constants for API endpoints
export const apiEndPoints = getEndPoints()
