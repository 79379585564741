import React, { ReactElement } from 'react'
import { Box, Typography, TypographyProps } from '@mui/material'
import CustomIcon, { CustomIconProps } from './CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { localeKeys } from '../resources/localeConstants'
import { useLocalize } from './CustomHooks'
import { flexStyles } from '../app_features/flexStyles'
import CustomizedButton from './CustomButton'

interface ZerothScreenProps {
	iconProps?: CustomIconProps
	tilteProps?: TypographyProps
	subTitleProps?: TypographyProps
	tilte?: string
	subTitle?: string
	showTitle?: boolean
	titleVariant?: keyof typeof typographyKeys
	subTitleVariant?: keyof typeof typographyKeys
	onClick?: () => void
	buttonText?: string
	disable?: boolean
	startIcon?: string
	icon?: string
	imageWidth?: string
	imageHeight?: string
}

/**
 * ZerothScreen Component
 * used for representing no data ui
 * This component represents a screen with a customizable icon and message.
 */
const ZerothScreen = ({
	tilte,
	subTitle,
	iconProps,
	tilteProps,
	subTitleProps,
	titleVariant,
	subTitleVariant,
	showTitle = true,
	onClick,
	buttonText,
	disable = false,
	startIcon,
	icon,
	imageWidth,
	imageHeight,
}: ZerothScreenProps): ReactElement => {
	const localize = useLocalize()
	return (
		<Box sx={{ ...flexStyles.flexColumnJustifyAlignCenter, height: '100%', width: '100%', gap: '20px', p: '30px' }}>
			{icon && (
				<CustomIcon
					name={icon}
					style={{ width: imageWidth ?? '420px', height: imageHeight ?? '235px' }}
					svgStyle={`width:${imageWidth ?? '420px'};height:${imageHeight ?? '235px'}`}
					{...iconProps}
				/>
			)}

			<Box sx={{ ...flexStyles.flexColumnCenter, gap: '10px' }}>
				{showTitle && (
					<Typography
						variant={titleVariant ?? typographyKeys.h3}
						sx={{ color: 'text.defaultHeading', textAlign: 'center' }}
						{...tilteProps}
					>
						{tilte ? localize(tilte) : localize(localeKeys.notApplicable)}
					</Typography>
				)}

				<Typography
					variant={subTitleVariant ?? typographyKeys.subtitle1}
					sx={{ color: 'text.primary', textAlign: 'center', maxWidth: '800px' }}
					{...subTitleProps}
				>
					{subTitle && localize(subTitle)}
				</Typography>
			</Box>

			{onClick && (
				<Box sx={{ mt: '16px' }}>
					<CustomizedButton
						textprops={{ text: localize(buttonText) ?? localize(localeKeys.notApplicable), variant: 'button1', color: 'text.subHeading' }}
						iconprops={{
							startIcon: startIcon ?? iconKeys.notifyIcon,
							iconSvgSx: 'width: 16px;height: 16px',
							iconSx: { height: '16px', width: '16px', marginBottom: '15px' },
						}}
						sxprops={{
							minWidth: '172px',
							height: '42px',
							borderRadius: '60px',
							backgroundColor: 'background.surface',
							border: '1px solid',
							borderColor: 'border.primary',
						}}
						onClick={onClick}
						disabled={disable}
					/>
				</Box>
			)}
		</Box>
	)
}

export default ZerothScreen
