import React, { CSSProperties, ReactElement } from 'react'
import { IconButton, TableCell, TableHead, TableRow, Typography, Box } from '@mui/material'
import CustomIcon from './CustomIcon'
import { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useLocalize } from './CustomHooks'
import { flexStyles } from '../app_features/flexStyles'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { localeKeys } from '../resources/localeConstants'
import { EmptyFunction, SetStateSortKeys, SetTableColumnProps } from '../app_features/types'
import { sortEnum } from '../utils/common/constants'

export const enum TextAlign {
	left = 'left',
	center = 'center',
	right = 'right',
	justify = 'justify',
	inherit = 'inherit',
}

export type TableColumnProps = {
	id: string
	label: string | string[]
	align: TextAlign
	width: number
	showCount: boolean
	sortApplied: boolean
	sortValue: number
	sortKey: string
	minWidth: number
	maxWidth: number
	showCheckbox: boolean
	tableCellCss: CSSProperties
	position: string
	isdoubleLabelWithIcons: boolean
	iconNames: string[]
}

export const defaultColumnProps: TableColumnProps = {
	id: '',
	label: '',
	align: TextAlign.left,
	width: 100,
	showCount: false,
	sortApplied: false,
	sortValue: 1,
	sortKey: '',
	minWidth: 80,
	maxWidth: 160,
	showCheckbox: false,
	tableCellCss: { height: '73px', padding: '10px' },
	position: 'none',
	isdoubleLabelWithIcons: false,
	iconNames: [],
}

export type SortProps = { key: string; value: number }

type AllListTableHeaderProps = {
	columns: TableColumnProps[]
	setColumns: SetTableColumnProps
	setSortKeys?: SetStateSortKeys
	dataCount?: number
	isDelete?: boolean
	selectedAll?: boolean
	onCheckClick?: EmptyFunction
	isRadioButton?: boolean
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.background.dropwdownBg, // color: theme.palette.text.primary,
		border: 'none',
		'&:hover': {
			'& .icon': {
				visibility: 'visible',
			},
		},
	},
}))

/**
 * Table header component with sortable columns and icons that are displayed
 * when the user hovers over the respective column.
 *
 * @param {AllListTableHeaderProps} props - The component props.
 * @returns {ReactElement} The rendered table header component.
 */

const CustomTableHeader = ({
	columns,
	setColumns,
	setSortKeys,
	dataCount,
	isDelete,
	selectedAll,
	onCheckClick,
	isRadioButton = false,
}: AllListTableHeaderProps): ReactElement => {
	const localize = useLocalize()

	return (
		<TableHead>
			<TableRow sx={{ height: '44px' }}>
				{isRadioButton && (
					<StyledTableCell
						align='center'
						sx={{
							width: '20px',
							p: '0px 20px',
						}}
					>
						<Box sx={{ ...flexStyles.flexRowCenter }}>
							<CustomIcon name={iconKeys.closeCircle} />
						</Box>
					</StyledTableCell>
				)}
				{isDelete && (
					<StyledTableCell
						align='center'
						sx={{
							width: '20px',
							p: '0px 20px',
						}}
					>
						<Box sx={{ ...flexStyles.flexRowCenter, cursor: 'pointer' }}>
							<CustomIcon name={selectedAll ? iconKeys.closeCircle : iconKeys.closeCircle} onClick={onCheckClick} />
						</Box>
					</StyledTableCell>
				)}

				{columns.map((column) => (
					<StyledTableCell
						key={column.id}
						align={column.align}
						sx={{
							position: column.position ?? 'none',
							minWidth: column.width,
							p: 0,
							pl: '10px',
							zIndex: column.position === 'sticky' ? 99 : 'none',
							top: column.position === 'sticky' ? 0 : 'none',
							left: column.position === 'sticky' ? 0 : 'none',
						}}
					>
						<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: column.align }}>
							{typeof column.label === 'string' && !column.isdoubleLabelWithIcons ? (
								<>
									{column.id === localeKeys.icon ? (
										<CustomIcon
											name={column.label}
											style={{
												height: '20px',
												width: '20px',
											}}
										/>
									) : (
										<Typography
											variant={typographyKeys.h5}
											sx={{
												color: 'text.defaultHeading',
											}}
										>
											{localize(column.label)}
										</Typography>
									)}
									<Typography
										variant={typographyKeys.h5}
										sx={{
											color: 'common.white',
										}}
									>
										{dataCount && dataCount > 0 && column.showCount ? ' (' + dataCount + ')' : ''}
									</Typography>
								</>
							) : column?.isdoubleLabelWithIcons && column?.label?.length === 2 ? (
								<Box sx={{ ...flexStyles.flexColumn, pt: '5px', pb: '5px' }}>
									<Box sx={{ ...flexStyles.flexRowAlignCenter }} gap={'3px'}>
										<Typography
											variant={typographyKeys.h5}
											sx={{
												color: 'common.white',
											}}
										>
											{localize(column?.label?.[0] ?? '')}
										</Typography>
										<CustomIcon
											name={column?.iconNames?.[0] ?? ''}
											style={{
												height: '16px',
												width: '16px',
											}}
										/>
									</Box>
									<Box sx={{ ...flexStyles.flexRowAlignCenter }}>
										<Typography
											variant={typographyKeys.h5}
											sx={{
												color: 'common.white',
											}}
										>
											{localize(column?.label?.[1] ?? '')}
										</Typography>
										<CustomIcon
											name={column?.iconNames?.[1] ?? ''}
											style={{
												height: '16px',
												width: '16px',
											}}
										/>
									</Box>
								</Box>
							) : null}

							{column.sortKey && (
								<IconButton
									className='icon'
									sx={{
										ml: 0.5,
										visibility: column.sortApplied ? 'visible' : 'hidden',
										opacity: column.sortApplied ? '1' : '0.5',
									}}
									size='small'
									onClick={() => {
										const sortProps: SortProps = {
											key: column.sortKey,
											value: column.sortValue,
										}

										const newData = columns.map((item) => {
											let sortApplied = false
											let sortValue = sortEnum.desc
											if (item.id === column.id) {
												sortApplied = true
												sortValue = item.sortValue === sortEnum.desc ? sortEnum.asc : sortEnum.desc
												sortProps.value = sortValue
											}
											return {
												...item,
												sortApplied: sortApplied,
												sortValue: sortValue,
											}
										})

										setColumns(newData)
										setSortKeys?.([sortProps])
									}}
								>
									<CustomIcon
										name={column.sortValue === sortEnum.desc && column.sortApplied ? iconKeys.closeCircle : iconKeys.closeCircle}
										style={{
											height: '24px',
											width: '24px',
										}}
									/>
								</IconButton>
							)}
						</Box>
					</StyledTableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export default CustomTableHeader
