import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import enNs1 from './resources/default/locales/en/ns1.json'
import enWhiteLabelNs1 from './resources/whitelabel/locales/en/ns1.json'
import esNs1 from './resources/default/locales/es/ns1.json'
import esWhiteLabelNs1 from './resources/whitelabel/locales/es/ns1.json'

import { commonConfig } from './environment'
import { stringConstants } from './utils/commonConstants'
import { CustomJSONObject } from './utils/utils'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-fs-backend'

export const defaultNS = 'ns1'

const resources = (): CustomJSONObject => {
	if (commonConfig.app.theme !== stringConstants.default) {
		return {
			en: {
				ns1: enWhiteLabelNs1,
			},
			es: {
				ns1: esWhiteLabelNs1,
			},
		}
	}

	return {
		en: {
			ns1: enNs1,
		},
		es: {
			ns1: esNs1,
		},
	}
}

i18next
	.use(initReactI18next)
	.use(LanguageDetector)
	.use(Backend)
	.init({
		debug: true,
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
		lng: 'en',
		fallbackLng: 'en',
		defaultNS,
		resources: resources(),
	})

export default i18next
