export const typographyKeys = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	button: 'button',
	button1: 'button1',
	subtitle1: 'subtitle1',
	subtitle2: 'subtitle2',
	label1: 'label1',
	label2: 'label2',
	label3: 'label3',
	body1: 'body1',
	body2: 'body2',
	callout: 'callout',
	callout1: 'callout1',
	callout2: 'callout2',
	callout3: 'callout3',
} as const

export const iconKeys = {
	hereSectionImg: 'hereSectionImg',
	achievementIcon: 'achievementIcon',
	getFitNowIcon: 'getFitNowIcon',
	menuIcon: 'menuIcon',
	layer2Icon: 'layer2Icon',
	layer1Icon: 'layer1Icon',
	layer3Icon: 'layer3Icon',
	humanBody: 'humanBody',
	coinIcon: 'coinIcon',
	zeroIcon: 'zeroIcon',
	profileIcon: 'profileIcon',

	arrowDown: 'arrowDown',
	arrowLeftCircle: 'arrowLeftCircle',
	star: 'star',
	rankingGrey: 'rankingGrey',
	starOrange: 'starOrange',
	exportBlue: 'exportBlue',
	information: 'information',
	closeFullscreen: 'closeFullscreen',
	fullScreen: 'fullScreen',
	first: 'first',
	second: 'second',
	third: 'third',
	closeCircle: 'closeCircle',
	rankOne: 'rankOne',
	rankTwo: 'rankTwo',
	rankThree: 'rankThree',
	crown: 'crown',
	coin: 'coin',
	microsoft: 'microsoft',
	leaderBoardChampion: 'leaderBoardChampion',
	emailSignup: 'emailSignup',
	leaderBoardZeroth: 'leaderBoardZeroth',
	completeProfile: 'completeProfile',
	location: 'location',
	gender: 'gender',
	profession: 'profession',

	medal1: 'medal1',
	medal2: 'medal2',
	medal3: 'medal3',
	quickStatsIcon: 'quickStatsIcon',
	continuePlay: 'continuePlay',
	overallRank: 'overallRank',
	seeLeaderboard: 'seeLeaderboard',

	caretDown: 'caretDown',
	calendar: 'calendar',
	calendarTwo: 'calendarTwo',
	clock: 'clock',
	overallRankBg: 'overallRankBg',
	logout: 'logout',
	profileImage: 'profileImage',
	viewProfile: 'viewProfile',
	mailProfile: 'mailProfile',
	phone: 'phone',
	camera: 'camera',
	arrowSquareLeft: 'arrowSquareLeft',
	arrowSquareRight: 'arrowSquareRight',
	zerothGames: 'zerothGames',
	notifyIcon: 'notifyIcon',
	editProfile: 'editProfile',
	profile: 'profile',
	email: 'email',
	closeWhiteCircle: 'closeWhiteCircle',
	success: 'success',
	failure: 'failure',
	play: 'play',
	arrowUp: 'arrowUp',
	notFound: 'notFound',
	notFoundMobile: 'notFoundMobile',
	profileComplete: 'profileComplete',
	delete: 'delete',

	starred: 'starred',
	unStarred: 'unStarred',
	legendTotal: 'legendTotal',
	highscoreLegend: 'highscoreLegend',

	unCheckedSquare: 'unCheckedSquare',
	checkedSquare: 'checkedSquare',
	minusSquareGreen: 'minusSquareGreen',
	search: 'search',
	accept: 'accept',
	reject: 'reject',
	rightArrowRoundWhite: 'rightArrowRoundWhite',
	people: 'people',
	groupProfile: 'groupProfile',
	playlistDefault: 'playlistDefault',
	removeFriend: 'removeFriend',
	threeDotsGreen: 'threeDotsGreen',
	editPencilBlack: 'editPencilBlack',
	filterGreen: 'filterGreen',
	searchRoundGreen: 'searchRoundGreen',
	playlistZeroth: 'playlistZeroth',
	addRoundWhite: 'addRoundWhite',
	joystickGreen: 'joystickGreen',
	crossRedRounded: 'crossRedRounded',
	tickRoundWhite: 'tickRoundWhite',
	emailWhite: 'emailWhite',
	viewLeaderBoardGreen: 'viewLeaderBoardGreen',
	removeGame: 'removeGame',
	viewLeaderBoardWhite: 'viewLeaderBoardWhite',

	trash: 'trash',
	unjoin: 'unjoin',
	tickOrange: 'tickOrange',
	gameIcon: 'gameIcon',
	friendReq: 'friendReq',
	friendReqActive: 'friendReqActive',
	crossBlack: 'crossBlack',
	nameBlack: 'nameBlack',
	emailBlack: 'emailBlack',

	tickRoundGreen: 'tickRoundGreen',
	mailGreen: 'mailGreen',
	rightNextroundedWhite: 'rightNextroundedWhite',

	expand: 'expand',
	collapse: 'collapse',
	cookie: 'cookie',
	peopleGreen: 'peopleGreen',
	epicPlaylist: 'epicPlaylist',
	acceptTick: 'acceptTick',
	ignore: 'ignore',
	videoGreen: 'videoGreen',
	rankings: 'rankings',
	keyFeatures: 'keyFeatures',
	playCircleWhite: 'playCircleWhite',
	competeTrack: 'competeTrack',
	signUpAndSetUp: 'signUpAndSetUp',
	playToGetFit: 'playToGetFit',
	request: 'request',
	arrSquLeftGrey: 'arrSquLeftGrey',
	arrSquRightGrey: 'arrSquRightGrey',
	scrollToTop: 'scrollToTop',
	alarm: 'alarm',

	ourMission: 'ourMission',
	ourVision: 'ourVision',

	innovativeWellness: 'innovativeWellness',
	technologyDriven: 'technologyDriven',
	communityFocused: 'communityFocused',
	desForProfess: 'desForProfess',
	plusIcon: 'plusIcon',

	stopGameDialogIcon: 'stopGameDialogIcon',
	leaderBoardZeroth2: 'leaderBoardZeroth2',

	rankBoxOne: 'rankBoxOne',
	rankBoxTwo: 'rankBoxTwo',
	rankBoxThree: 'rankBoxThree',
	infoCircle: 'infoCircle',

	crownRankOne: 'crownRankOne',
	crownRankTwo: 'crownRankTwo',
	crownRankThree: 'crownRankThree',

	flowersRankOne: 'flowersRankOne',
	flowersRankTwo: 'flowersRankTwo',
	flowersRankThree: 'flowersRankThree',
	timeSpent: 'timeSpent',

	frameLeft: 'frameLeft',
	frameRight: 'frameRight',
}
