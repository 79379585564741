import { Box, Drawer, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'
import CustomIcon from './CustomIcon'
import { iconKeys, typographyKeys } from '../resources/resourceConstants'
import { flexStyles, globalStyles } from '../app_features/flexStyles'
import { useLocalize } from './CustomHooks'
import { localeKeys } from '../resources/localeConstants'
import CustomizedButton from './CustomButton'
import CookiesDialog from './CookiesDialog'
import HtmlEmbedDialog from './HtmlEmbedDialog'

function deleteCookie(name: string) {
	document.cookie = `${name}=; Max-Age=-99999999; path=/; domain=${location.hostname};`
}

export function acceptAllCookies() {
	// Update consent via gtag
	if (typeof window.gtag === 'function') {
		window.gtag('consent', 'update', {
			ad_storage: 'granted',
			analytics_storage: 'granted',
		})
	}

	// Save consent in localStorage
	localStorage.setItem(
		'userCookieConsent',
		JSON.stringify({
			performanceCookies: 'enabled',
		})
	)
}

export function rejectAllCookies() {
	// Update consent via gtag
	if (typeof window.gtag === 'function') {
		window.gtag('consent', 'update', {
			ad_storage: 'denied',
			analytics_storage: 'denied',
		})
	}

	// Delete relevant cookies (e.g., Google Analytics cookies)
	deleteCookie('_ga')
	deleteCookie('_ga_YCCD5943MZ')

	// Save rejection in localStorage
	localStorage.setItem(
		'userCookieConsent',
		JSON.stringify({
			performanceCookies: 'disabled',
		})
	)
}

const CookiesDrawer = () => {
	const localize = useLocalize()
	const [openDrawer, setOpenDrawer] = useState(false)
	const [openManageDialog, setOpenManageDialog] = useState(false)
	const [readMoreOpen, setreadMoreOpen] = useState(false)
	const theme = useTheme()

	const downMd = useMediaQuery(theme.breakpoints.down('ml'))
	const downlg = useMediaQuery(theme.breakpoints.down('lg'))

	useEffect(() => {
		// Check if the user has already provided cookie consent
		const userConsentString = localStorage.getItem('userCookieConsent')

		// If user consent is found
		if (userConsentString) {
			const userConsent = JSON.parse(userConsentString)

			// Update gtag consent if gtag is available
			if (typeof window.gtag === 'function') {
				window.gtag('consent', 'update', {
					ad_storage: userConsent?.performanceCookies === 'enabled' ? 'granted' : 'denied',
					analytics_storage: userConsent?.performanceCookies === 'enabled' ? 'granted' : 'denied',
				})
			}

			// Hide drawer if consent is already given
			setOpenDrawer(false)
		} else {
			// Show drawer if no consent is found
			setOpenDrawer(true)
		}
	}, [])

	function onAcceptAllCookies() {
		acceptAllCookies()
		setOpenDrawer(false)
	}

	function onRejectAllCookies() {
		rejectAllCookies()
		setOpenDrawer(false)
	}

	const handleMangeButtonClick = () => {
		setOpenManageDialog(true)
	}

	const handleClose = () => {
		acceptAllCookies()
		setOpenDrawer(false)
	}

	const handleManageDialogClose = () => {
		setOpenManageDialog(false)
	}

	return (
		<>
			<Drawer
				PaperProps={{ sx: { backgroundColor: 'background.default', backgroundImage: 'none' } }}
				anchor='bottom'
				open={openDrawer}
				variant='persistent'
				ModalProps={{
					disablePortal: true,
					disableEnforceFocus: true,
					disableScrollLock: true,
				}}
				sx={{
					'& .MuiDrawer-paper': {
						boxSizing: 'border-box',
						width: '100%',
						minHeight: '210px',
						maxHeight: '300px',
						...globalStyles.hideScroll,
					},
				}}
			>
				<Box sx={{ position: 'relative', height: '100%' }}>
					<Box sx={{ ...flexStyles.flexRowFlexEnd, position: 'absolute', width: '100%', p: '10px' }}>
						<IconButton size='small' style={{ height: '34px', width: '34px' }} onClick={handleClose}>
							<CustomIcon name={iconKeys.closeCircle} style={{ height: '30px', width: '30px' }} svgStyle='height: 30px; width: 30px;' />
						</IconButton>
					</Box>

					<Box sx={{ ...flexStyles.flexColumn, p: downMd ? '25px 35px' : '25px 80px', gap: '25px' }}>
						<Box sx={{ ...flexStyles.flexColumn, gap: '12px' }}>
							<Typography variant={typographyKeys.h3} sx={{ color: 'text.defaultHeading' }}>
								{localize(localeKeys.cookieConsentTitle)}
							</Typography>

							<Box sx={{ display: 'inline' }}>
								<Typography variant={typographyKeys.subtitle1} sx={{ color: 'text.primary', display: 'inline' }}>
									{localize(localeKeys.cookieConsentMessage) + ' '}
								</Typography>

								<Typography
									variant={typographyKeys.subtitle1}
									sx={{ color: 'text.subHeading', textDecoration: 'underline', display: 'inline', cursor: 'pointer' }}
									onClick={() => setreadMoreOpen(true)}
								>
									<strong> {localize(localeKeys.cookiePolicyLinkText)}</strong>
								</Typography>
							</Box>
						</Box>

						<Box sx={downMd ? { ...flexStyles.flexColumn, gap: '16px' } : { ...flexStyles.flexRowSpaceBetweenAlignCenter, gap: '20px' }}>
							<Box
								sx={
									downMd ? { ...flexStyles.flexColumn, gap: '16px' } : { ...flexStyles.flexRowSpaceBetweenAlignCenter, gap: '20px' }
								}
							>
								<CustomizedButton
									textprops={{
										text: localize(localeKeys.manageCookiesButtonText),
										variant: typographyKeys.button1,
										color: 'text.defaultHeading',
									}}
									sxprops={{
										minWidth: downlg ? '200px' : '236px',
										height: '46px',
										backgroundColor: 'background.surface',
										border: '1px solid',
										borderColor: 'border.active',
									}}
									onClick={handleMangeButtonClick}
									iconprops={{
										parentSx: { ...flexStyles.flexCenterSpaceBetween, width: '100%' },
										endIcon: iconKeys.rightNextroundedWhite,
									}}
								/>
								<CustomizedButton
									textprops={{
										text: localize(localeKeys.rejectAllCookiesButtonText),
										variant: typographyKeys.button1,
										color: 'text.defaultHeading',
									}}
									sxprops={{
										minWidth: downlg ? '200px' : '236px',
										height: '46px',
										backgroundColor: 'background.surface',
										border: '1px solid',
										borderColor: 'border.active',
									}}
									onClick={onRejectAllCookies}
									iconprops={{
										parentSx: { ...flexStyles.flexCenterSpaceBetween, width: '100%' },
										endIcon: iconKeys.rightNextroundedWhite,
									}}
								/>
							</Box>

							<CustomizedButton
								textprops={{ text: localize(localeKeys.acceptAllCookiesButtonText), variant: typographyKeys.button1 }}
								sxprops={{
									minWidth: downlg ? '200px' : '236px',
									height: '46px',
									backgroundColor: 'background.background2',
								}}
								onClick={onAcceptAllCookies}
								iconprops={{
									parentSx: { ...flexStyles.flexCenterSpaceBetween, width: '100%' },
									endIcon: iconKeys.rightNextroundedWhite,
								}}
							/>
						</Box>
					</Box>
				</Box>
			</Drawer>

			{openManageDialog && (
				<CookiesDialog
					openManageDialog={openManageDialog}
					handleDialogClose={handleManageDialogClose}
					acceptAllCookies={acceptAllCookies}
					rejectAllCookies={rejectAllCookies}
					handleDrawerClose={() => setOpenDrawer(false)}
				/>
			)}

			<IconButton
				size='small'
				sx={{ position: 'fixed', bottom: '10px', left: '10px', height: '34px', width: '34px', zIndex: 2 }}
				onClick={handleMangeButtonClick}
			>
				<CustomIcon name={iconKeys.cookie} style={{ height: '32px', width: '32px' }} svgStyle='height: 32px; width: 32px' />
			</IconButton>

			<HtmlEmbedDialog
				open={readMoreOpen}
				setOpen={setreadMoreOpen}
				url={'https://games.health4work.life/webapp-assets/cookie-policy-health4worklife.html'}
			/>
		</>
	)
}

export default CookiesDrawer
