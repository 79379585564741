export const localeKeys = {
	gamingLeaderBoard: 'gamingLeaderBoard',
	home: 'home',
	features: 'features',
	howItWorks: 'howItWorks',
	aboutUs: 'aboutUs',
	crashError: 'crashError',
	timeoutError: 'timeoutError',
	networkError: 'networkError',
	trustedByUsers: 'trustedByUsers',
	unlashYourInnerChampion: 'unlashYourInnerChampion',
	playMoveWin: 'playMoveWin',
	crushYourGoals: 'crushYourGoals',
	joinTheFunFree: 'joinTheFunFree',
	getFitToday: 'getFitToday',
	dashboard: 'dashboard',
	games: 'games',
	leaderBoard: 'leaderBoard',
	readyToCrushFitnes: 'readyToCrushFitnes',
	readyToCrushSome: 'readyToCrushSome',
	fitness: 'fitness',
	goals: 'goals',
	today: 'today',
	goalsToday: 'goalsToday',
	allGames: 'allGames',

	playNow: 'playNow',
	currentRank: 'currentRank',
	instructions: 'instructions',
	todayEarned: 'todayEarned',
	points: 'points',
	leaderboard: 'leaderboard',
	totalEarned: 'totalEarned',
	icon: 'icon',
	notApplicable: 'notApplicable',
	edit: 'edit',
	delete: 'delete',
	rank: 'rank',
	name: 'name',
	gender: 'gender',
	profession: 'profession',
	country: 'country',
	state: 'state',
	city: 'city',
	score: 'score',
	unableToConnect: 'Unable to connect. Please check your internet connection and try again.',
	logout: 'logout',
	leaderboardWaiting: 'leaderboardWaiting',
	leaderboardZerothTitle: 'leaderboardZerothTitle',
	leaderboardZerothSubtitle: 'leaderboardZerothSubtitle',
	completeProfile: 'completeProfile',
	completeProfileSubtitle: 'completeProfileSubtitle',
	submit: 'submit',
	byContinuing: 'byContinuing',
	termsOfServices: 'termsOfServices',
	privacyPolicy: 'privacyPolicy',
	and: 'and',
	noOptionsFound: 'noOptionsFound',
	enterYourProfession: 'enterYourProfession',

	hey: 'hey',
	timeToMovePlayWin: 'timeToMovePlayWin',
	quickStats: 'quickStats',
	todayScore: 'todayScore',
	youHaveEarned: 'youHaveEarned',
	pointsToday: 'pointsToday',
	loginStreak: 'loginStreak',
	days: 'days',
	totalGamesPlayed: 'totalGamesPlayed',
	youHavePlayed: 'youHavePlayed',
	gamesOverall: 'gamesOverall',
	yourOverallRanking: 'yourOverallRanking',
	global: 'global',
	india: 'india',
	karnataka: 'karnataka',
	bengaluru: 'bengaluru',
	chaseTheWorld: 'chaseTheWorld',
	yourAre: 'yourAre',
	pointsAwayFromRank1: 'pointsAwayFromRank1',
	timeSpent: 'timeSpent',
	dailyAverage: 'dailyAverage',
	perWeek: 'perWeek',
	gamesTiming: 'gamesTiming',
	congratsYou: 'congratsYou',
	spot: 'spot',
	continuePlaying: 'continuePlaying',
	seaLeaderboard: 'seaLeaderboard',
	viewProfile: 'viewProfile',
	signout: 'signout',
	needCameraAccess: 'needCameraAccess',
	clickAllow: 'clickAllow',
	allowCameraAndPlay: 'allowCameraAndPlay',
	oopsFunOnPause: 'oopsFunOnPause',
	newGamesNotification: 'newGamesNotification',
	notifyMe: 'notifyMe',
	you: 'you',
	profileMsg: 'profileMsg',
	fullName: 'fullName',
	displayName: 'displayName',
	emailId: 'emailId',
	mobileNumber: 'mobileNumber',
	enterFullName: 'enterFullName',
	enterDisplayName: 'enterDisplayName',
	enterEmailId: 'enterEmailId',
	cancel: 'cancel',
	update: 'update',
	enterYourPhoneNumber: 'enterYourPhoneNumber',
	searchCountryOrCode: 'searchCountryOrCode',
	stopGame: 'stopGame',
	todaysLeaderboard: 'todaysLeaderboard',
	last7daysScore: 'last7daysScore',
	noDataTitle: 'noDataTitle',
	noDataSubtitle: 'noDataSubtitle',
	notFoundTitle: 'notFoundTitle',
	notFoundSubtitle: 'notFoundSubtitle',
	deleteAccount: 'deleteAccount',
	deletedRecovered: 'deletedRecovered',
	verifyThisAccount: 'verifyThisAccount',
	otpMobileMessage: 'otpMobileMessage',
	termsText: 'termsText',
	termsAndConditionsText: 'termsAndConditionsText',
	privacyText: 'privacyText',
	incoorectOtp: 'incoorectOtp',
	didntRecieve: 'didntRecieve',
	resendOtp: 'resendOtp',

	gamePlaylist: 'gamePlaylist',
	list: 'list',
	total: 'total',
	highestScore: 'highestScore',
	showing: 'showing',
	of: 'of',
	totalScore: 'totalScore',
	highScore: 'highScore',
	noGames: 'noGames',
	noGamesSubtitle: 'noGamesSubtitle',
	search: 'search',
	view: 'view',
	more: 'more',
	gamesAdded: 'gamesAdded',
	playslistOwner: 'playslistOwner',
	youhaveBeenInvited: 'youhaveBeenInvited',
	decline: 'decline',
	joinNow: 'joinNow',
	players: 'players',
	play: 'play',

	pickYourGames: 'pickYourGames',
	pickYourGamesSubtitle: 'pickYourGamesSubtitle',
	createPlaylist: 'createPlaylist',
	friendsPlaylist: 'friendsPlaylist',
	myPlaylist: 'myPlaylist',
	removeFriend: 'removeFriend',
	removeFriendDescrip: 'removeFriendDescrip',
	yesRemove: 'yesRemove',
	keep: 'keep',
	takeMeBack: 'takeMeBack',
	friends: 'friends',
	searchForGame: 'searchForGame',
	addMoreGames: 'addMoreGames',
	viewLeaderBoard: 'viewLeaderBoard',
	removeGame: 'removeGame',
	editPlaylist: 'editPlaylist',
	editProfile: 'editProfile',
	oopsNoPlaylistsYet: 'oopsNoPlaylistsYet',
	noPlaylistSubtitle: 'noPlaylistSubtitle',
	createYourPlaylistNow: 'createYourPlaylistNow',
	playlist: 'playlist',
	youHaveGotAnInvite: 'youHaveGotAnInvite',
	yourFriendJustInvitedYou: 'yourFriendJustInvitedYou',
	playlistName: 'playlistName',
	playlistOwner: 'playlistOwner',
	addMoreFriends: 'addMoreFriends',
	addFriends: 'addFriends',
	selectFriendsFromYourList: 'selectFriendsFromYourList',
	searchFriendsNameOrEmail: 'searchFriendsNameOrEmail',
	noPlayersAvailable: 'noPlayersAvailable',
	addSomePlayerstoplaylist: 'addSomePlayerstoplaylist',
	deletePlaylistTitle: 'deletePlaylistTitle',
	deletePlaylistSubtitle: 'deletePlaylistSubtitle',
	deleteConfirmText: 'deleteConfirmText',
	searchFriend: 'searchFriend',
	noFriendsAdded: 'noFriendsAdded',
	areyousureGameRemoved: 'areyousureGameRemoved',
	actionUndone: 'actionUndone',
	confirmGameRemoval: 'confirmGameRemoval',
	searchByNameOrEmail: 'searchByNameOrEmail',
	unFriend: 'unFriend',
	nothingToSeeHere: 'nothingToSeeHere',

	cookieConsentTitle: 'cookieConsentTitle',
	cookieConsentMessage: 'cookieConsentMessage',
	cookiePolicyLinkText: 'cookiePolicyLinkText',
	manageCookiesButtonText: 'manageCookiesButtonText',
	rejectAllCookiesButtonText: 'rejectAllCookiesButtonText',
	acceptAllCookiesButtonText: 'acceptAllCookiesButtonText',
	consentManager: 'consentManager',
	saveSettings: 'saveSettings',

	privacy_intro_title: 'privacy_intro_title',
	privacy_intro_content: 'privacy_intro_content',
	strictly_necessary_cookies_title: 'strictly_necessary_cookies_title',
	strictly_necessary_cookies_status: 'strictly_necessary_cookies_status',
	strictly_necessary_cookies_description: 'strictly_necessary_cookies_description',
	cookie_list_cookie_name: 'cookie_list_cookie_name',
	cookie_list_cookie_type: 'cookie_list_cookie_type',
	cookie_list_category: 'cookie_list_category',
	cookie_list_expiration_duration: 'cookie_list_expiration_duration',
	cookie_list_platform_name: 'cookie_list_platform_name',
	cookie_list_cookie_description: 'cookie_list_cookie_description',
	performance_cookies_title: 'performance_cookies_title',
	performance_cookies_status: 'performance_cookies_status',
	performance_cookies_description: 'performance_cookies_description',
	performance_cookie_1_cookie_name: 'performance_cookie_1_cookie_name',
	performance_cookie_1_cookie_type: 'performance_cookie_1_cookie_type',
	performance_cookie_1_category: 'performance_cookie_1_category',
	performance_cookie_1_expiration_duration: 'performance_cookie_1_expiration_duration',
	performance_cookie_1_platform_name: 'performance_cookie_1_platform_name',
	performance_cookie_1_cookie_description: 'performance_cookie_1_cookie_description',
	performance_cookie_2_cookie_name: 'performance_cookie_2_cookie_name',
	performance_cookie_2_cookie_type: 'performance_cookie_2_cookie_type',
	performance_cookie_2_category: 'performance_cookie_2_category',
	performance_cookie_2_expiration_duration: 'performance_cookie_2_expiration_duration',
	performance_cookie_2_platform_name: 'performance_cookie_2_platform_name',
	performance_cookie_2_cookie_description: 'performance_cookie_2_cookie_description',

	performance_cookies_subPoint1: 'performance_cookies_subPoint1',
	performance_cookies_subPoint2: 'performance_cookies_subPoint2',
	performance_cookies_subPoint3: 'performance_cookies_subPoint3',
	performance_cookies_Description2: 'performance_cookies_Description2',

	friendsName: 'friendsName',
	yourecieved: 'yourecieved',
	newFriendRequest: 'newFriendRequest',

	cookieList: 'cookieList',
	getStartedWatchThis: 'getStartedWatchThis',
	dontShowThisAgain: 'dontShowThisAgain',
	yourBrowserDoesNotSupportVideoTag: 'yourBrowserDoesNotSupportVideoTag',

	noFriendReqRecieved: 'noFriendReqRecieved',
	noFriendReqSent: 'noFriendReqSent',
	permission_denied_title: 'permission_denied_title',
	permission_denied_message_line1: 'permission_denied_message_line1',
	permission_denied_message_line2: 'permission_denied_message_line2',
	permission_denied_message_line3: 'permission_denied_message_line3',
	permission_denied_button_text: 'permission_denied_button_text',

	about: 'about',
	us: 'us',
	foundedIn: 'foundedIn',
	companyName: 'companyName',
	missionStatement: 'missionStatement',
	companyJourney: 'companyJourney',
	health4Work: 'health4Work',
	platformDescription: 'platformDescription',

	ourMissionTitle: 'ourMissionTitle',
	mission: 'mission',
	missionDescription: 'missionDescription',
	ourVisionTitle: 'ourVisionTitle',
	vision: 'vision',
	visionDescription: 'visionDescription',

	coreValuesTitle: 'coreValuesTitle',
	values: 'values',
	innovativeWellnessSolutionTitle: 'innovativeWellnessSolutionTitle',
	innovativeWellnessSolutionDescription: 'innovativeWellnessSolutionDescription',
	technologyDrivenTitle: 'technologyDrivenTitle',
	technologyDrivenDescription: 'technologyDrivenDescription',
	communityFocusedTitle: 'communityFocusedTitle',
	communityFocusedDescription: 'communityFocusedDescription',
	designedForProfessionalsTitle: 'designedForProfessionalsTitle',
	designedForProfessionalsDescription: 'designedForProfessionalsDescription',
	addGames: 'addGames',

	termsAndConditions: 'termsAndConditions',
	someshwaraSoftwareLimited: 'someshwaraSoftwareLimited',
	giveYourPlaylistAName: 'giveYourPlaylistAName',
	personalizeYourPlayList: 'personalizeYourPlayList',
	playListName: 'playListName',
	enterPlayListName: 'enterPlayListName',
	playListDescription: 'playListDescription',
	describeTheThemeOrPurpose: 'describeTheThemeOrPurpose',
	selectAPlaylistPicture: 'selectAPlaylistPicture',
	chooseAPredefinedImage: 'chooseAPredefinedImage',
	searchForAGame: 'searchForAGame',
	previous: 'previous',
	nextNameDetails: 'nextNameDetails',
	nextAddLogo: 'nextAddLogo',
	uploadFromFiles: 'uploadFromFiles',
	orQuickPicks: 'orQuickPicks',
	ignore: 'ignore',
	accept: 'accept',
	whereAreYourFriends: 'whereAreYourFriends',
	rallyFriendsMessage: 'rallyFriendsMessage',
	readyToBringYourFriends: 'readyToBringYourFriends',
	sendInviteMessage: 'sendInviteMessage',
	enterName: 'enterName',
	enterEmailAddress: 'enterEmailAddress',
	inviteMore: 'inviteMore',
	sendAnInvite: 'sendAnInvite',
	invitedBy: 'invitedBy',
	yesUnfriend: 'yesUnfriend',
	inviteAFriend: 'inviteAFriend',
	selectPlaylist: 'selectPlaylist',
	yesAccept: 'yesAccept',
	all: 'all',
	accepted: 'accepted',
	invited: 'invited',
	declined: 'declined',
	leavePlaylist: 'leavePlaylist',
	leavePlaylistMessage: 'leavePlaylistMessage',
	yesLeave: 'yesLeave',
	dateOfBirth: 'dateOfBirth',
	selectGender: 'selectGender',
	selectCountry: 'selectCountry',
	selectState: 'selectState',
	selectCity: 'selectCity',

	buildYourEpicGameCollection: 'buildYourEpicGameCollection',
	turnChallengeIntoVictory: 'turnChallengeIntoVictory',
	searchPlaylistName: 'searchPlaylistName',
	addToPlaylist: 'addToPlaylist',
	emptyPlaylistMessage: 'emptyPlaylistMessage',
	createPlaylistPrompt: 'createPlaylistPrompt',

	oopsYoureAboutToLeave: 'oopsYoureAboutToLeave',
	looksLikeYouClickedBackButton: 'looksLikeYouClickedBackButton',
	keepPlaying: 'keepPlaying',
	stopTheGame: 'stopTheGame',
	noRanksAt: 'noRanksAt',
	startPlayingNowToEarn: 'startPlayingNowToEarn',

	move: 'move',
	win: 'win!',
	playhome: 'play,',
	key: 'key',
	interactiveFitnessGames: 'interactiveFitnessGames',
	playInteractiveGames: 'playInteractiveGames',
	letsPlay: 'letsPlay',
	playGames: 'playGames',
	beatScores: 'beatScores',
	getFit: 'getFit',
	competeGlobally: 'competeGlobally',
	poweredByAI: 'poweredByAI',
	joinLeaderboard: 'joinLeaderboard',
	howIt: 'howIt',
	works: 'works',
	timeToFlexThoseMuscles: 'timeToFlexThoseMuscles',
	startPlayingNow: 'startPlayingNow',
	unJoinPlaylist: 'unJoinPlaylist',
	received: 'received',
	sent: 'sent',
	friendRequests: 'friendRequests',
	acceptRequest: 'acceptRequest',

	gamesList: 'gamesList',
	perDay: 'perDay',
	daysInARow: 'daysInARow',
	keepItUp: 'keepItUp',
	youHaventPlayedYetToday: 'youHaventPlayedYetToday',
	weeklyPerformanceOverview: 'weeklyPerformanceOverview',
}
