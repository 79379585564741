// authSlice.ts

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
	endGameThunk,
	fetchGamePreferenceThunk,
	leaderBoardDialogThunk,
	leaderBoardThunk,
	startGameThunk,
	updateGamePreferenceThunk,
} from './singleGameThunk'
import { GamePreferenceResponse, GameSessionDetails, LeaderboardAndTotalCountData } from './signleGameDatamodal'

// Define the shape of the authentication state
interface SingleGamesProps {
	leaderBoardListData: LeaderboardAndTotalCountData | null
	sessionId: string
	gameEndData: GameSessionDetails[] | null
	leaderBoardDialogData: LeaderboardAndTotalCountData | null
	gamePreferenceData: GamePreferenceResponse | null
	inFullScreen: boolean
	leaderBoardDialogDataTotal: number
}

// Initial state for the authentication slice
const initialState: SingleGamesProps = {
	leaderBoardListData: null,
	sessionId: '',
	gameEndData: null,
	leaderBoardDialogData: null,
	leaderBoardDialogDataTotal: 0,
	gamePreferenceData: null,
	inFullScreen: false,
}

export const startGame = createAsyncThunk('singleGame/singleGame', startGameThunk)

export const endGame = createAsyncThunk('singleGame/endGame', endGameThunk)
export const leaderBoardRight = createAsyncThunk('singleGame/leaderBoard', leaderBoardThunk)
export const leaderBoardDialogApi = createAsyncThunk('singleGame/leaderBoardDialog', leaderBoardDialogThunk)

export const fetchGamePreferenceApi = createAsyncThunk('singleGame/fetchGamePreference', fetchGamePreferenceThunk)
export const UpdateGamePreferenceApi = createAsyncThunk('singleGame/updateGamePreference', updateGamePreferenceThunk)

// Authentication slice with reducers and extraReducers for async actions
const SingleGameSlice = createSlice({
	name: 'singleGame',
	initialState,
	reducers: {
		setSessionId: (state, action) => {
			state.sessionId = action.payload
		},
		setLeaderboardList: (state, action) => {
			state.leaderBoardListData = action.payload
		},

		setInFullscreen: (state, action) => {
			state.inFullScreen = action.payload
		},
	},
	extraReducers: (builder) => {
		// Handle the fulfilled case of the login API call
		builder.addCase(startGame.fulfilled, (state, action) => {
			state.sessionId = action.payload.data.data.sessionId
		})
		// Handle the fulfilled case of the login API call
		builder.addCase(endGame.fulfilled, (state, action) => {
			state.gameEndData = action.payload.data.data
		})
		builder.addCase(leaderBoardRight.fulfilled, (state, action) => {
			state.leaderBoardListData = action.payload.data.data
		})
		builder.addCase(leaderBoardDialogApi.fulfilled, (state, action) => {
			state.leaderBoardDialogData = action.payload.data.data
			state.leaderBoardDialogDataTotal = action.payload.data.data.totalCount
		})
		builder.addCase(fetchGamePreferenceApi.fulfilled, (state, action) => {
			state.gamePreferenceData = action.payload.data.data
		})
	},
})

export const { setSessionId, setLeaderboardList, setInFullscreen } = SingleGameSlice.actions

export default SingleGameSlice.reducer
