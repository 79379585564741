import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { viewDashboardThunk } from './thunk'
import { DashboardData } from './dataModal'

interface DashboardState {
	dashboardData: DashboardData | null
}

const initialState: DashboardState = {
	dashboardData: null,
}

export const viewDashboardApi = createAsyncThunk('dashboard/viewDashboard', viewDashboardThunk)

// Slice with reducers and extraReducers for handling async actions
const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(viewDashboardApi.fulfilled, (state, action) => {
			// state.error = null
			state.dashboardData = action.payload.data.data
		})
	},
})

export default dashboardSlice.reducer
